
let vm = null;

export default {
  name: "SwiperCustomer",
  data() {
    return {
      activeSlide: 0,
      swiperOptionTop: {
        loop: false,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 10,
        autoplay: false,
        allowTouchMove: false,
        effect: "fade",
        fadeEffect: { crossFade: true },
        speed: 1000,
      },
      swiperOptionThumbs: {
        direction: "vertical",
        loop: false,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 24,
        centeredSlides: true,
        slidesPerView: "auto",
        slideToClickedSlide: true,
        allowTouchMove: false,
        autoplay: false,

        // slidesToShow: 5,
        on: {
          // slideChange() {
          //   vm.activeSlides(this.activeIndex);
          //   // console.log(this.activeIndex);
          // },
        },
      },
    };
  },

  methods: {
    // activeSlides(id) {
    //   console.log(id);
    // },
  },
  created() {
    vm = this;
  },
  mounted() {
    this.$nextTick(() => {
      const swiperCustomerPreview = this.$refs.swiperCustomerPreview.$swiper;
      const swiperCustomerThumbs = this.$refs.swiperCustomerThumbs.$swiper;
      swiperCustomerThumbs.controller.control = swiperCustomerPreview;
      swiperCustomerPreview.controller.control = swiperCustomerThumbs;
    });
    if (window.innerWidth > 960) {
      this.swiperOptionThumbs.autoplay = { delay: 5000 };
      this.swiperOptionTop.autoplay = { delay: 5000 };
    }
  },
};
